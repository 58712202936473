
































































import { Component, Vue, Watch } from 'vue-property-decorator';
import GeneralPage from '@/components/general/layout/GeneralPage.vue';
import OptionCard from '@/components/swine/layout/OptionCard.vue';
import { UserSessionStore } from '@/store/swine/user-session';
import { ProfileStores } from '@/store/swine/profile';
import { Role, Species, Modules } from '@/utils/constants'

@Component({
  components: {
    GeneralPage,
    OptionCard,
  },
})
export default class ModuleSelection extends Vue {
  speciesSelection: string = '';
  moduleSelection: string = '';
  isButtonDisabled: Boolean = false;
  speciesAccess: string[] = [];
  moduleAccess: string[] = [];
  store = ProfileStores.profile;
  speciesEnum = Species;

  userSessionDetails = UserSessionStore.detail;
  species = [
    {
      name: 'Swine',
      value: Species.Swine,
      image: 'img-swine.svg',
    },
    {
      name: 'Poultry',
      value: Species.Poultry,
      image: 'img-poultry.svg',
    },
  ];

  modules = [
    {
      title: 'Lung Lesion Scoring',
      value: Modules.LungLesionScoring,
      image: 'img-lung_leson_selection.svg',
    },
    {
      title: 'Bio Protection',
      value: Modules.BioProtection,
      image: 'img-bio-protection.svg',
    },
    {
      title: 'Learning Hub',
      value: Modules.LeaningHub,
      image: 'img-learning-hub.svg',
    },
  ];

  created() {
    this.disableButton();
  }

  @Watch('speciesSelection')
  @Watch('moduleSelection')
  disableButton() {
    if (
      this.speciesSelection === Species.Poultry ||
      (this.speciesSelection === Species.Swine && this.moduleSelection !== '')
    ) {
      this.isButtonDisabled = false;
    } else {
      this.isButtonDisabled = true;
    }
  }

  mounted() {
    this.updateSpeciesModuleAccess();
  }


  updateSpeciesModuleAccess() {
    if (this.store.userProfile) {
      const speciesList: string[] = []
      const moduleList: string[] = []

      this.store.userProfile.module.forEach(
        (userModule) => {
          const speciesName = userModule.speciesName;
          const moduleName = userModule.moduleName;
          const role = this.store.userProfile ? this.store.userProfile.role : '';

          const SpeciesRestricted = (speciesName === Species.Poultry && role !== Role.Regional && role !== Role.Admin)

          if (!SpeciesRestricted) {
            speciesList.push(speciesName)
          }

          moduleList.push(moduleName)
        }
      );

      this.speciesAccess = speciesList
      this.moduleAccess = moduleList
    }
  }

  moduleChanged(module: string) {
    this.moduleSelection = module;
  }

  speciesChanged(species: string) {
    this.speciesSelection = species;
  }

  handleContinue() {
    if (this.speciesSelection === Species.Swine && this.moduleSelection !== '') {
      this.$router.push({
        name: 'SwineDashboard',
      });
    } else if (this.speciesSelection === Species.Poultry) {
      this.$router.push({
        name: 'PoultryDashboard',
      });
    }
  }

  disabledSpeciesAccess(species: string) {
    if (this.speciesAccess.includes(species)) {
      return false;
    }
    return true;
  }

  disabledModuleAccess(module: string) {
    if (this.moduleAccess.includes(module)) {
      return false;
    }
    return true;
  }
}
