
















import { Vue, Prop, Component } from 'vue-property-decorator';

@Component({})
export default class OptionCard extends Vue {
  @Prop({ default: 'router-link' })
  tag!: string;

  @Prop({ default: 'CategorySelection' })
  routeDestination!: string;

  @Prop({ default: '' })
  className!: string;

  @Prop({ required: true })
  image!: string;

  @Prop({ required: true })
  title!: string;
}
